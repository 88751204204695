import React from 'react'
import Layout from '../components/Layout'

const NotFoundPage = () => (
  <Layout>
    <section className='hero is-large'>
      <div className='hero-body'>
        <h1 className='py-4 pl-4 is-size-3'>
          <span className='is-size-1'>404</span> Sorry, there's nothing here...
        </h1>
      </div>
    </section>
  </Layout>
)

export default NotFoundPage
